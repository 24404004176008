import React from 'react';
import { PageProps } from 'gatsby';

import { HomeHero, SEO } from '@/components';

const Home: React.FC<PageProps> = () => (
  <>
    <SEO />
    <HomeHero />
  </>
);

export default Home;
